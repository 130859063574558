//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import Popfield from "./components/Popfield.vue";
import Popdatefield from "./components/Popdatefield.vue";
import CopyUsers from "./components/CopyUsers.vue";
import Processfield from "./components/Processfield.vue";

export default {
  name: "Home",
  components: {
    "pop-field": Popfield,
    "pop-datefield": Popdatefield,
    "copy-users": CopyUsers,
    "process-field": Processfield,
  },
  data() {
    return {
      saveSate: false,
      outOB: {
        hhrDeptName: "",
      },
      activeName: "1",
      enteryData: {
        hhrEmpid: "", //人员编号
        hhrEmpName: "", //员工姓名
        hhrEmpBeforeName: "", //曾用名
        hhrEmpOtherName: "", //英文名
        oaPosnName: "", //OA所属岗位
        oaPosnId: "", //OA所属岗位Id
        oaDeptName: "", //OA所属部门
        oaDeptId: "", //OA所属部门id
        hhrCountry: "", //国籍
        hhrCountryMeaning: "",
        hhrSex: "", //性别
        hhrCertificateType: "", //身份证件类型
        hhrCertificateNum: "", //身份证件号码
        hhrBirthDate: "", //出生日期
        hhrMobile: "", //联系电话
        hhrChannelBig: "", //招聘渠道大类
        hhrChannelSmall: "", //招聘渠道小类
        hhrChannelOther: "", //其他渠道分类
        hhrPernr: "", //内部推荐人
        hhrEvaluation: "", //面试评价
        hhrHealthStatus: "", //健康状况
        hhrEntryDate: "", //入职日期
        hhrFrzt: "", //法人主体
        hhrEmpClass: "", //员工类型
        hhrLevel: "", //管理层级
        hhrDeptCode: "", //入职部门
        hhrDeptCodeMeaning: "",
        hhrPosnCode: "", //入职岗位Code
        hhrRsClass: "", //人事子范围
        hhrIfAccommodation: "", //是否住宿
        hhrYaccount: "", //域账号
        hhrCompanyEmali: "", //公司邮箱
        hhrAuthority: "", //信息权限
        hhrBgnDate: "",
        hhrEndDate: "",
        _status: "create",
        tenantId: 0,
      },
      actionList: {
        CarbonCopy: {
          text: "抄送",
          color: "orange",
        },
        Approved: {
          text: "同意",
          color: "green",
        },
        Jump: {
          text: "驳回",
          color: "deep-orange",
        },
      },
      copyVal: [],
      pageData: null,
      active: 0,

      approveData: {
        value: "",
        columns: [],
        pickStatus: false,
        searchValue: "",
      },
      jumpListData: {
        value: "",
        columns: [],
        pickStatus: false,
      },
      approveAdviceValue: "",
      copyArr: [],
      copyString: "",
      processNode: 0,
      formDisable: false,
      ctfrom: false,
      hhrBiogData: {},
    };
  },
  created() {
    window.document.title = "OAdemo";
    this.onLoad();
    // this.getInitData();
  },
  mounted() {
    window.document.title = "OA流程";
    //this.wxConfig();
  },
  methods: {
    getInitData() {
      console.log("init");
    },
    //保存信息权限
    saveCtData() {
      Toast.loading({
        message: "正在请求...",
        duration: 0,
      });
      let payload = this.enteryData;
      payload._status = "update";
      console.log(payload);

      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName: "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerEntry",
        },
        payload: JSON.stringify([payload]),
      };
      this.saveSate = true;
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: { processDefinitionKey: "PER_ENTRY_DEF" },
        })
        .then((res) => {
          Toast.clear();
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "保存成功！",
                  timeout: 1000
                });
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },
    saveFormData() {
      // Toast.loading({
      //   message: "正在请求...",
      //   duration: 0,
      // });

      let payload = {
        orgPerBiogList: [
          {
            hhrEmpid: this.enteryData.hhrEmpid,
            hhrBirthDate: this.enteryData.hhrBirthDate,
            hhrCertificateNum: this.enteryData.hhrCertificateNum,
            hhrCertificateType: this.enteryData.hhrCertificateType,
            hhrCountry: this.enteryData.hhrCountry,
            hhrBgnDate: this.enteryData.hhrBgnDate,
            hhrEmpBeforeName: this.enteryData.hhrEmpBeforeName,
            hhrEmpOtherName: this.enteryData.hhrEmpOtherName,
            hhrEndDate: this.enteryData.hhrEndDate,
            hhrGender: this.enteryData.hhrSex,
            hhrOrgPerBiogAttr02: this.enteryData.hhrMobile,
            hhrEmpName: this.enteryData.hhrEmpName,
            _status: "create",
          },
        ],
      };
      if (this.hhrBiogData.orgPerBiogList.length > 0) {
        payload.orgPerBiogList[0]._status = "update";
        payload.orgPerBiogList[0]._token =this.hhrBiogData.orgPerBiogList[0].token;
        //payload.orgPerBiogList[0]._token ="epQMH28qAk27JPMhd1LSAre4dHeZwEr1YMWbdZVQ1xZeMXpqmjYPRxD4xfbV7E3gE5NCyJXAaf20qoLnbVF2pMRzCXDmcomk76jWdVNV88c="
        payload.orgPerBiogList[0].id = this.hhrBiogData.orgPerBiogList[0].id;
        payload.orgPerBiogList[0].objectVersionNumber = this.hhrBiogData.orgPerBiogList[0].objectVersionNumber;

      } else {
        payload.orgPerBiogList._status = "create";
      }
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {},
        payload: JSON.stringify(payload),
      };
      console.log(payload);
     this.saveSate = true
      this.axios
        .post("/boogooForm/savePerson", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
              this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log(resultData);
              if (!resultData.failed) {
                this.$q.notify({
                  color: "primary",
                  textColor: "white",
                  type: "positive",
                  position: "center",
                  message: "保存成功",
                  timeout: 1000,
                });
                this.loadFormData();
              } else {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000
                });
              }
              // this.pageData = resultData;
              // this.list = resultData.content;
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: "出错了",
              });
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast.clear();
          Toast("请求出错了");
        });
    },
    onSubmit() {
      let obj = {
        approveAdviceValue: "Approved",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onRejected() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写拒绝原因",
          timeout: 1000,
        });
        return;
      }
      let obj = {
        approveAdviceValue: "Rejected",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onJumpBack() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写驳回原因",
          timeout: 1000,
        });
        return;
      }
      if (this.jumpListData.columns.length > 0) {
        this.jumpListData.pickStatus = true;
        return;
      } else {
        Toast.loading({
          message: "数据加载中...",
          duration: 0,
        });
        let taskId = this.$route.query.id;
        let postData = {
          pathVariableMap: {
            organizationId: "0",
            taskId: taskId,
          },
          headerParamMap: {},
          requestParamMap: {},
          payload: "",
        };

        this.axios
          .post("/boogoo/getJumpList", postData)
          .then((res) => {
            Toast.clear();
            if (res && res.data) {
              if (res.data.status == "200") {
                let resultData = JSON.parse(res.data.payload);
                console.log(resultData);
                if (!resultData.failed) {
                  for (let i = 0; i < resultData.length; i++) {
                    this.jumpListData.columns.push({
                      text: resultData[i].actName,
                      value: resultData[i].actId,
                    });
                  }

                  this.jumpListData.pickStatus = true;
                } else {
                  this.$q.notify({
                    color: "red-5",
                    textColor: "white",
                    icon: "warning",
                    position: "center",
                    message: resultData.message,
                    timeout: 1000
                  });
                }
                // this.pageData = resultData;
                // this.list = resultData.content;
              } else {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: "出错了",
                  timeout: 1000
                });
              }
            }
          })
          .catch(() => {
            Toast.clear();
            Toast("请求出错了");
          });
      }
    },
    doprocess(payload) {
      Toast.loading({
        message: "正在请求...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          taskId: this.$route.query.id,
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //当前登录人员工ID 必填
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogoo/executeTaskActionV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "204") {
   
              this.doCallback();
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    onLoad() {
      let taskId = this.$route.query.id;
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          taskId: taskId, //当前流程编号
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //员工ID
        },
        payload: "",
      };
      this.axios
        .post("/boogoo/queryTaskV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log(resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000
                });
              } else {
                this.pageData = resultData;
                switch (resultData.taskDefinitionKey) {
                  case "sid-sWtMxZZF-HgMt-4qUP-8R3w-qtDgc1o2zRIZ":
                    this.formDisable = true;
                    this.processNode = 1;
                    this.activeName = "1";
                    break; // 信息岗开通账户/权限
                  case "sid-WsldjNWo-nmCC-4Ej4-82DL-IS4wE830Cfy2":
                    this.ctfrom = true;
                    this.processNode = 2;
                    this.activeName = "2";
                    break; // 人事岗补录信息
                  case "sid-mSZgJsC4-RgR4-4VQu-8C8a-ybMTeS3k3YZT":
                    this.ctfrom = true;
                    this.processNode = 3;
                    break; // 员工本人信息补录
                  case "sid-hIL7cVss-xRi2-4rmF-9CKu-Z2sCwt2Ur1OW":
                    this.formDisable = true;
                    this.ctfrom = true;
                    this.processNode = 4;

                    break; // 确认入职
                  // case 'sid-1mOKSG3B-sxTs-4nQJ-8B1d-xSrDDWStC6tc':node = '3';break; // 招聘岗填写员工基本信息
                  default:
                }
                this.loadFormData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadFormData() {
      let payload = [{ hhrBusinessKey: this.pageData.businessKey }];
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "query",
          entityName: "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerEntry",
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogooForm/selectMethod", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("loadFormData", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000
                });
              } else {
                this.assignBiog(resultData.content[0]);
                this.loadEmpData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadEmpData() {
      let hhrpayload = [{ hhrEmpid: this.enteryData.hhrEmpid }];
      let hhrpostData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "query",
          entityName: "com.boogoo.hhr.corehr.per.api.dto.OrgPerBiogDTO",
        },
        payload: JSON.stringify(hhrpayload),
      };
      this.axios
        .post("/boogooForm/selectMethod", hhrpostData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000
                });
              } else {
                //this.enteryData = resultData.content[0];
              
         
                let desData =  resultData[0];
                this.hhrBiogData = desData;
                if (desData.orgPerBiogList.length > 0) {
                  this.assignBiog(desData.orgPerBiogList[0]);
                     console.log("this.desData.orgPerBiogList[0] ", desData.orgPerBiogList[0]);
                }
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    assignBiog(biogData) {
      for (let key in biogData) {
        this.enteryData[key] = biogData[key];
      }
      console.log("assignBiog", this.enteryData);
    },
    popConfirm(obj) {
      switch (obj.key) {
        case "hhrPosnCode":
          this.outOB.hhrDeptName = obj.value.obj.hhrDeptName;
          this.enteryData.hhrDeptCode = obj.value.obj.hhrDeptCode;
          break;
      }
      this.enteryData[obj.key] = obj.value.value;
      // console.log(obj.key,this[obj.key]);
      console.log(this.enteryData);
    },
    dateConfirm(obj) {
      this.enteryData[obj.key] = obj.value;
      console.log(this.enteryData);
    },
    jumpListConfirm(obj) {
      console.log(obj);
      let payload = {
        assignee: "",
        action: "jump",
        comment: this.approveAdviceValue,
        jumpTarget: obj.value,
        jumpTargetName: obj.name,
        currentTaskId: this.$route.query.id,
      };
      this.doprocess(payload);
    },
    doCallback() {
      this.$q.notify({
        color: "primary",
        textColor: "white",
        type: "positive",
        position: "center",
        message: "操作成功",
        timeout: 1500,
      });
      this.$router.replace("/oaplatform/list");
    },
    confirmCopy(obj) {
      this.copyArr = obj;
      let tempCopy = [];
      for (let i = 0; i < this.copyArr.length; i++) {
        tempCopy.push(this.copyArr[i].value);
      }
      this.copyString = tempCopy.toString();
      console.log("confirmCopy", this.copyArr);
      console.log("copyString", this.copyString);
    },
  },
};
