//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import popconfig from "../config/popconfig";
//import qs from "qs";
export default {
  props: {
    popType: String,
    defaultVal: {
      type: String,
      default: '',
    },
    pageData:Object
  },
  name: "Popfield",
  data() {
    return {
      fieldVal: "",
      label: "国籍",
      rule: [],
      valPicker: false,
      needSearch: false,
      searchVal: "",
      searchHold: "请输入姓名查询",
      pickTitle: "请选择",
      colArr: [],
      colobj: {
        value: "countryCode",
        name: "countryName",
      },
      ajaxData: {
        url: "/boogoo/queryPerBiogLovValues",
        requestData: {},
      },
      resultKey: null,
      specifiedVariableName:''
    };
  },
  created() {
    this.initPop();
  },
  methods: {
    initPop() {
      this.ajaxData = popconfig[this.popType].ajaxData;
      this.colobj = popconfig[this.popType].colobj;
      this.rule = popconfig[this.popType].rule;
      this.label = popconfig[this.popType].label;
      this.searchHold = popconfig[this.popType].searchHold;
      this.needSearch = popconfig[this.popType].needSearch;
      this.searchVal = popconfig[this.popType].searchVal;
      this.resultKey = popconfig[this.popType].resultKey;
      this.fieldVal=this.defaultVal
    },
    showList(reload) {
      console.log(this.ajaxData.requestData);
      if (!reload && this.colArr.length > 0) {
        this.valPicker = true;
        return;
      }
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.ajaxData.requestData.requestParamMap.processInstanceId= this.pageData.processInstanceId
      this.axios
        .post(this.ajaxData.url, this.ajaxData.requestData)
        .then((res) => {
          console.log("res", res);
          Toast.clear();
          this.valPicker = true;
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              this.specifiedVariableName=resultData[0].specifiedVariableName
              this.buildleaderArr(resultData[0].sequenceList);
              console.log(resultData);
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    buildleaderArr(data) {
      console.log("buildleaderArr", data);
      this.colArr = [];
      for (var i = 0; i < data.length; i++) {
        this.colArr.push({
          value: data[i][this.colobj.value],
          text: data[i][this.colobj.name],
          obj: data[i],
        });
      }
    },
    processConfirm(val) {
      this.valPicker = false;
      this.fieldVal=val.text;
      let postData = {
        pathVariableMap: {
          organizationId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          processInstanceId: this.pageData.processInstanceId,
          specifiedVariableName: this.specifiedVariableName,
          specifiedVariableValue: val.value,
        },
        payload: "",
      };

      this.axios
        .post("/boogoo/saveSpecifiedSequence", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.statusCodeValue == "200") {
              if (res.data.payload != "") {
                let resultData = JSON.parse(res.data.payload);
                console.log(resultData);
                if (resultData.failed) {
                  this.$q.notify({
                    color: "red-5",
                    textColor: "white",
                    icon: "warning",
                    position: "center",
                    message: resultData.message,
                  });
                }
              }
              // this.pageData = resultData;
              // this.list = resultData.content;
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: "出错了",
              });
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
  },
  mounted() {},
};
